<template>
    <div class="h-full">
        <fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange"
            @onPageChange="onPageChange" :queryParam="queryParam">
            <div class="h-handle-button" slot="buttonGroup">
                <div class="h-b">
                    <el-button type="primary" size="small" v-right-code="'Product:Edit'"
                        @click="addProductInfo">新增</el-button>
                </div>
                <div class="h-b">
                    <el-button type="primary" size="small" @click="deleteProduct"
                        v-right-code="'Product:Deleteproduct'">删除</el-button>
                </div>
                <div class="h-b">
                    <el-dropdown @command="doexcute" v-right-code="'Product:Exportfileorder'">
                        <el-button type="primary">
                            导入|导出<i class="el-icon-caret-bottom el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="importProductExcelTemplate"
                                v-right-code="'Product:Exportfileorder'">导入</el-dropdown-item>
                            <!-- <el-dropdown-item command="exportProductExcel"
                                v-right-code="'Customer_Right_Basic_Product_ExportProduct'">普通商品导出</el-dropdown-item> -->
                            <!-- <el-dropdown-item command="exportTemplateProductExcel"
                                v-right-code="'Customer_Right_Basic_Product_ExportProduct'">物资重量及体积规格商品导出
                            </el-dropdown-item> -->
                            <el-dropdown-item command="exportCustomExcel"
                                v-right-code="'Product:Exportfileorder'">导出</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <div class="h-b">
                    <el-dropdown @command="doexcute" v-right-code="'Product:Enabledproduct'">
                        <el-button type="primary">
                            启用|停用<i class="el-icon-caret-bottom el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="enabledProduct"
                                v-right-code="'Product:Enabledproduct'">批量启用</el-dropdown-item>
                            <el-dropdown-item command="disableProduct"
                                v-right-code="'Product:Enabledproduct'">批量停用</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <div class="h-b">
                    <el-dropdown @command="doexcute" v-right-code="'Product:Productissue'">
                        <el-button type="primary">
                            下发<i class="el-icon-caret-bottom el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="selectProuctIssue"
                                v-right-code="'Product:Productissue'">选中下发</el-dropdown-item>
                            <el-dropdown-item command="allProductIssue"
                                v-right-code="'Product:Productissue'">全部下发</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <div class="h-b">
                    <el-dropdown @command="doexcute" v-right-code="'Product:Batchupdateclassify'">
                        <el-button type="primary">
                            批量修改<i class="el-icon-caret-bottom el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="batchUpdateClassifyDialog"
                                v-right-code="'Product:Batchupdateclassify'">修改商品分类</el-dropdown-item>
                            <el-dropdown-item command="batchRelateShopDialog"
                                v-right-code="'Product:Batchupdateclassify'">修改可用店铺</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
            <p slot="elList">
                <el-table ref="productInfoListTable" :data="dataSource.Result" border
                    @selection-change="handleSelectionChange" highlight-current-row>
                    <el-table-column type="selection" width="55"
                        v-if="(!config.isDetailDisplay)"></el-table-column>
                    <el-table-column v-for="(col,index,count)  in dataSource.ColDefs.BodyFieldParams" :key="index"
                        :prop="col.FieldName" :label="col.DisplayName" :render-header="bindFilter(queryParam,col)"
                        :fixed="index==0&&(!config||!config.isDetailDisplay)"
                        :min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
                        v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<2))">
                        <template slot-scope="scope">
                            <span v-if="col.FieldName==='ProductNo'"><span type="text" @click="rowClick(scope.row)"
                                    style="text-decoration:underline;color:#1874CD;cursor:pointer">{{ scope.row[col.FieldName] }}</span></span>
                            <span v-else-if="col.FieldName==='DisylayProductProcessDesc'">
                                <el-popover trigger="hover" placement="top">
                                    <p>{{ scope.row.ProductProcessDesc }}</p>
                                    <div slot="reference">
                                        <span size="medium">{{ scope.row[col.FieldName] }}</span>
                                    </div>
                                </el-popover>
                            </span>
                            <span v-else> {{ scope.row[col.FieldName] }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </p>
        </fixed-list>
        <el-dialog title="批量修改商品分类" :visible.sync="updateClassifyVisible" width="10%">
            <span>
                商品分类：
                <el-select v-model="productClassify.productClassifyCode"
                    @change="classifyChange(productClassify.productClassifyCode)">
                    <el-option label="未分类" value="0"></el-option>
                    <el-option v-for="item in productClassifyList" :key="item.Code"
                        :label="item.Name" :value="item.Code">
                    </el-option>
                </el-select>
            </span>
            <div slot="footer" class="dialog-footer">
                <el-button @click="updateClassifyVisible = false">取 消</el-button>
                <el-button type="primary" @click="batchUpdateClassify">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="批量修改商品可用店铺" :visible.sync="relateShopVisible">
            <span style="color:red;">*</span>
            <el-switch v-model="isLimitUseShop" inactive-text="限制可用店铺"></el-switch>
            <el-table :data="ShopInfoList" :default-sort="{prop: 'ShopNo', order: 'descending'}" border
                highlight-current-row>
                <el-table-column label="" width="50" align="center">
                    <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.isChecked" :disabled="!isLimitUseShop"></el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column prop="ShopNo" label="店铺编码" sortable align="center">
                    <template slot-scope="scope">
                        <span v-model="scope.row.ShopNo">{{scope.row.ShopNo}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="ShopName" label="店铺名称" sortable align="center">
                    <template slot-scope="scope">
                        <span v-model="scope.row.ShopName">{{scope.row.ShopName}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="ShopContactName" label="联系人" sortable align="center">
                    <template slot-scope="scope">
                        <span v-model="scope.row.ShopContactName">{{scope.row.ShopContactName}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="ShopContactPhone" label="联系人电话" sortable align="center">
                    <template slot-scope="scope">
                        <span v-model="scope.row.ShopContactPhone">{{scope.row.ShopContactPhone}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="ShopAddress" label="门店地址" align="center">
                    <template slot-scope="scope">
                        <span v-model="scope.row.DisplayAddress">{{scope.row.DisplayAddress}}</span>
                    </template>
                </el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button @click="relateShopVisible = false">取 消</el-button>
                <el-button type="primary" @click="batchRelateShop">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="提示信息" :visible.sync="remindVisible">
            <span>以下商品库存不为零，是否继续停用：{{ProhibitRemind}}</span><br />
            <div slot="footer" class="dialog-footer">
                <el-button @click="remindVisible = false">关 闭</el-button>
                <el-button type="primary" @click="DozskiMethod">仅停用零库存商品</el-button>
                <el-button type="primary" @click="FdoapMethod">强制停用所有商品</el-button>
            </div>
        </el-dialog>
        <DialogEx :options="importConfigTemplate" title="自定义商品模板导入" @onOk="onImportOk">
            <ImportProductTemplate ref="importProductTemplate" :options="importConfigTemplate"
                :dataSource="importDataSource"></ImportProductTemplate>
        </DialogEx>
        <DialogEx :options="exportConfigTemplate" title="自定义商品模板导出" @onOk="onExportOk">
            <ExportProduct ref="exportProduct" :options="exportConfigTemplate" :dataSource="exportDataSource">
            </ExportProduct>
        </DialogEx>
    </div>
</template>
<script>
    export default {
        mounted() {
            this.Utils.lazy(() => {
                var _this = this;
                this.Event.$on("reloadProductInfoPageList", () => this.reloadPageList());
                this.Event.$on("onAddProductInfo", () => this.addProductInfo());
            });
            this.initialize();
        },
        data() {
            return {
                isLimitUseShop: false,
                CustomerCode: '',
                ProhibitRemind: '',
                customerList: [],
                warehouseList: [],
                WarehouseId: '',
                remindVisible: false,
                relateShopVisible: false,
                ShopInfoList: [],
                productClassify: {
                    productClassifyCode: '',
                    productClassifyName: ''
                },
                productClassifyList: [],
                updateClassifyVisible: false,
                multipleSelection: [],
                queryParam: {
                    PageIndex: 1,
                    PageSize: 10,
                    CustomerCode: ''
                },
                dataSource: {
                    ColDefs: {},
                    Result: [],
                    TotalCount: 0
                },
                importConfigTemplate:
                {
                    visible: false,
                },
                importDataSource: {
                    CustomerID: "",
                    CustomerCode: "",
                    CustomerName: "",
                },
                exportDataSource: {
                    busType: "",
                    queryParam: "",
                    url: ""
                },
                exportConfigTemplate: {
                    visible: false,
                    size: 'small',
                },
                ProductUnitCategorys: [
                    { label: '内包装', value: 2, status: true },
                    { label: '箱', value: 3, status: true },
                    { label: '托盘', value: 4, status: true }
                ],
            }
        },

        props: {
            config: {
                isDetailDisplay: false,
                isButtonlDisplay: false,
            },
            option: {}
        },
        methods: {
            reloadPageList: function () {
                this.initialize();
            },
            initialize() {
                this.onPageChange(this.queryParam);
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                this.$emit("onCheckRow", this.multipleSelection);
            },
            onPageChange(param) {
                var _this = this;
                this.queryParam = param;
                this.$ajax.query("omsapi/product/getproductlist", "post", param, data => {

                    _this.dataSource = data;
                });
            },
            onDataSourceChange(ds) {
                var _this = this;

                _this.dataSource = {
                    ColDefs: {
                        BodyFieldParams: []
                    },
                    Result: [],
                    TotalCount: 0
                };
                _this.$nextTick(function () {
                    _this.dataSource = ds;

                });


            },
            rowClick(row) {
                var _this = this;
                this.$ajax.send("omsapi/product/getproductinfobyid", "post", row, (data) => {
                    data.Result.CoverFileList=[];
                    data.Result.DetailFileList=[];
                    data.Result.FileList=[];
                    var imageSysAttachments = data.Result.ImageSysAttachments;
                    for (var i = 0; i < imageSysAttachments.length; i++) {
                        if (imageSysAttachments[i].AttachmenCategory == 500) {
                            var coverFile = { uid: imageSysAttachments[i].FileTitle, name: imageSysAttachments[i].FileName, url: imageSysAttachments[i].AttachmentUrl };
                            data.Result.CoverFileList.push(coverFile);
                        }
                        else if (imageSysAttachments[i].AttachmenCategory == 501) {
                            var File = { uid: imageSysAttachments[i].FileTitle, name: imageSysAttachments[i].FileName, url: imageSysAttachments[i].AttachmentUrl };
                            data.Result.DetailFileList.push(File);
                        }
                    }
                    var fileSysAttachments =data.Result.FileSysAttachments;
                    for (var i = 0; i < fileSysAttachments.length; i++){
                            var file = { FileName: fileSysAttachments[i].FileName, AttachmentUrl: fileSysAttachments[i].AttachmentUrl };
                            data.Result.FileList.push(file);
                        }
                    _this.Event.$emit("clearEditProductInfoForm");
                    if (data.Result.IsPriceSensitive) {
                        data.Result.originalPurchasePrice = data.Result.PurchasePrice;
                        data.Result.originalCostPrice = data.Result.CostPrice;
                        data.Result.originalSalePrice = data.Result.SalePrice;
                        data.Result.PurchasePrice = data.Result.PurchasePrice ? "****" : "";
                        data.Result.CostPrice = data.Result.CostPrice ? "****" : "";
                        data.Result.SalePrice = data.Result.SalePrice ? "****" : "";
                    }
                    _this.onChangeEditDataSource(data.Result);
                });
            },
            importProductExcelTemplate() {
                var _this = this;
				this.$ajax.send("omsapi/customerinfo/getcurrentcustomers", "get", {}, (data) => {
				/* 	_this.importTemplateConfig.visible = true;
					_this.importTemplateConfig.title = "上传订单导入文件";
					_this.orderType = 1;
					_this.importDataSource = {};
					_this.importDataSource.CustomerList=data.Result; */
					
					_this.importConfigTemplate.visible = true;
					_this.importDataSource = {};
					_this.importDataSource.CustomerList=data.Result;
				});
				
				
		/* 		
                _this.importConfigTemplate.visible = true;
                _this.importDataSource = {}; */
            },
            exportProductExcel: function () {
                var _this = this;
                var queryParam = _this.Utils.exportQueryParam(_this.queryParam);
                var str = JSON.stringify(queryParam);
                var urlFile = "omsapi/product/exportproduct?queryParam=" + str;
                _this.Utils.export(urlFile);
            },
            exportTemplateProductExcel: function myfunction() {
                var _this = this;
                var queryParam = _this.Utils.exportQueryParam(_this.queryParam);
                var str = JSON.stringify(queryParam);
                var urlFile = "omsapi/product/exportTemplateProduct?queryParam=" + str;
                _this.Utils.export(urlFile);
            },
            onImportOk: function (options) {
               var _this = this;
               _this.$refs.importProductTemplate.saveFlie(options);
               this.onPageChange(_this.queryParam);
            },
            exportCustomExcel: function () {
                var _this = this;
                var str = encodeURIComponent(JSON.stringify(_this.queryParam));
                _this.exportConfigTemplate.visible = true;
                _this.exportDataSource = { busType: "5", queryParam: str, url: "omsapi/product/exportCustomTemplateProduct" };
            },
            onExportOk: function (options) {
                var _this = this;
                _this.$refs.exportProduct.submit(options);
            },
            addProductInfo() {
                this.$ajax.send("omsapi/product/getemptyproductinfo", "get", { code: this.queryParam.customerCode }, (data) => {
                    data.Result.FileList=[];
                    this.onChangeEditDataSource(data.Result);
                });
            },
            
            onChangeEditDataSource(data) {
                this.config.isDetailDisplay = true;
                this.$emit("onChangeEditDataSource", data);
            },
            customerChange(code) {
                this.queryParam.customerCode = code;
                this.queryParam.WarehouseId = '';
                this.WarehouseId = '';

                this.onPageChange(this.queryParam);

                var _this = this;
                var customerID;
                _this.customerList.forEach(function (item) {
                    if (item.CustomerCode == code) {
                        customerID = item.CustomerID;
                    }
                });
                this.$ajax.send("omsapi/projectinfo/getprojectselectlistbycustomerid", "get", { id: customerID }, (data) => {
                    _this.warehouseList = data.Result;
                });

            },
            enabledProduct() {
                if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                    this.Utils.messageBox(`未选择商品.`, "error");
                    return false;
                }
                var This = this;
                for (var i = 0; i < this.multipleSelection.length; i++) {
                    if (this.multipleSelection[i].ProductStatus == 100) {
                        This.Utils.messageBox("已启用商品，不能重复设置", "error");
                        return false;
                    }
                }
                this.Utils.confirm({
                    title: "是否启用商品?",
                    content: "启用后数据内容会发生改变,是否确认启用?"
                }, () => {

                    this.$ajax.send("omsapi/product/enabledproduct", "post", this.multipleSelection, (data) => {
                        This.onPageChange(This.queryParam);
                        This.Utils.messageBox("商品启用成功.", "success");
                    });
                }, () => {
                    This.Utils.messageBox("已取消启用.", "info");
                });
            },
            disableProduct() {
                if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                    this.Utils.messageBox(`未选择商品.`, "error");
                    return false;
                }
                var This = this;
                for (var i = 0; i < this.multipleSelection.length; i++) {
                    if (this.multipleSelection[i].ProductStatus == 200) {
                        This.Utils.messageBox("已停用商品，不能重复设置", "error");
                        return false;
                    }
                }
                this.$ajax.send("omsapi/product/validatedisableproduct", "post", this.multipleSelection, (data) => {
                    if (data.OperationDesc == '' || data.OperationDesc == null) {
                        this.Utils.confirm({
                            title: "是否停用商品?",
                            content: "停用后数据内容会发生改变,是否确认禁用?"
                        }, () => {
                            this.$ajax.send("omsapi/product/disableproduct", "post", this.multipleSelection, (data) => {
                                This.onPageChange(This.queryParam);
                                This.Utils.messageBox("商品停用成功.", "success");
                            });
                        }, () => {
                            This.Utils.messageBox("已取消停用.", "info");
                        });
                    }
                    else {
                        This.ProhibitRemind = data.OperationDesc;
                        This.remindVisible = true;
                    }
                });
            },
            DozskiMethod() {
                this.validatedisableproduct(1);
            },
            FdoapMethod() {
                this.validatedisableproduct(2);
            },
            validatedisableproduct(value) {
                var This = this;
                var type = false;
                var url = 'disableproduct';
                if (value == 1) {
                    type = true;
                    url = 'filterdisableproduct';
                }
                this.$ajax.send("omsapi/product/" + url, "post", this.multipleSelection, (data) => {
                    This.onPageChange(This.queryParam);
                    This.Utils.messageBox("商品停用成功.", "success");
                });
                this.remindVisible = false;
            },
            deleteProduct() {
                if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                    this.Utils.messageBox(`未选择商品.`, "error");
                    return false;
                }
                var This = this;
                for (var i = 0; i < this.multipleSelection.length; i++) {
                    if (this.multipleSelection[i].ProductStatus == 100) {
                        This.Utils.messageBox("只有非正常状态商品才能删除", "error");
                        return false;
                    }
                }
                this.Utils.confirm({
                    title: "是否删除商品?",
                    content: "删除后数据内容会发生改变,是否确认删除?"
                }, () => {
                    this.$ajax.send("omsapi/product/deleteProduct", "post", this.multipleSelection, (data) => {
                        This.onPageChange(This.queryParam);
                        This.Utils.messageBox("商品删除成功.", "success");
                    });
                }, () => {
                    This.Utils.messageBox("已取消删除.", "info");
                });
            },
            doexcute: function (command) {
                switch (command) {
                    case "importProductExcelTemplate":
                        this.importProductExcelTemplate();
                        break;
                    case "exportProductExcel":
                        this.exportProductExcel();
                        break;
                    case "exportTemplateProductExcel":
                        this.exportTemplateProductExcel();
                        break;
                    case "exportCustomExcel":
                        this.exportCustomExcel();
                        break;
                    case "enabledProduct":
                        this.enabledProduct();
                        break;
                    case "disableProduct":
                        this.disableProduct();
                        break;
                    case "batchRelateShopDialog":
                        this.batchRelateShopDialog();
                        break;
                    case "batchUpdateClassifyDialog":
                        this.batchUpdateClassifyDialog();
                        break;
                    case "selectProuctIssue":
                        this.selectProuctIssue();
                        break;
                    case "allProductIssue":
                        this.allProductIssue();
                        break;
                    default: break;
                }
            },
            selectProuctIssue() {
                var _this = this;
                if (_this.multipleSelection == undefined || _this.multipleSelection.length <= 0) {
                    _this.Utils.messageBox("请选择需要下发的商品");
                    return;
                }
                _this.multipleSelection.forEach(function (item) {
                    if (item.ProductStatus == 0) {//启用停用状态
                        _this.Utils.messageBox("不能选择草稿状态商品");
                        return;
                    }
                });
                _this.issue(_this.multipleSelection);
            },
            allProductIssue() {
                var _this = this;
                var allSelection = [];
                if (_this.dataSource.Result == undefined || _this.dataSource.Result.length <= 0) {
                    _this.Utils.messageBox("当前页没有可下发的商品");
                    return;
                }
                _this.dataSource.Result.forEach(function (item) {
                    if (item.IssueStatus == 0) {//未下发状态
                        allSelection.push(item);
                    }
                });
                if (allSelection.length > 0) {
                    _this.issue(allSelection);
                } else {
                    _this.Utils.messageBox("当前页没有可下发的商品");
                }
            },
            issue(selection) {
                var _this = this;
                var productNos = _this.Utils.selectionsToArr(selection, "ProductNo");
                this.$ajax.send("omsapi/product/productissue", "post", productNos, (result) => {
                    _this.Utils.messageBox("下发成功！", "success");
                    this.reloadPageList();
                    return;
                });
            },
            batchRelateShopDialog() {
                var _this = this;
                if (_this.multipleSelection == undefined || _this.multipleSelection.length <= 0) {
                    _this.Utils.messageBox("请选择需要关联的商品");
                    return;
                }
                var isVisible = true;
                //_this.multipleSelection.forEach(function (item) {
                //    if (item.ProductStatus == 100) {//启用状态
                //        isVisible = false;
                //        _this.Utils.messageBox("只能选择草稿、停用状态的商品");
                //        return;
                //    }
                //});
                this.$ajax.send("omsapi/customershop/getallcustomershop", "get", {}, (result) => {
                    if (result.Result != null) {
                        if (result.Result.length > 0) {
                            _this.ShopInfoList = result.Result;
                        }
                    }
                });
                _this.relateShopVisible = isVisible;
            },
            batchRelateShop() {
                var _this = this;
                var isHaveChecked = false;//是否勾选了关联店铺
                var checkedShopInfoList = [];//选中的店铺
                _this.ShopInfoList.forEach(function (shop) {
                    if (shop.isChecked) {
                        isHaveChecked = true;
                        checkedShopInfoList.push(shop.ShopNo);
                    }
                });
                var checkedShopNos = checkedShopInfoList.join(';');
                if (!isHaveChecked || !_this.isLimitUseShop) {//没有勾选关联店铺
                    _this.relateShopVisible = false;
                    return;
                } else {
                    _this.multipleSelection.forEach(function (product) {
                        product.ShopNos = checkedShopNos;
                        product.IsLimitUseShop = _this.isLimitUseShop;
                    });
                }
                this.$ajax.send("omsapi/product/batchrelateshopinfo", "post", _this.multipleSelection, (result) => {
                    _this.Utils.messageBox("批量关联店铺成功！", "success");
                    _this.relateShopVisible = false;
                    return;
                });
            },
            batchUpdateClassifyDialog() {
                var _this = this;
                if (_this.multipleSelection == undefined || _this.multipleSelection.length <= 0) {
                    _this.Utils.messageBox("请选择需要修改的商品");
                    return;
                }
                var isVisible = true;
                _this.multipleSelection.forEach(function (item) {
                    if (item.ProductStatus == 100) {//启用状态
                        isVisible = false;
                        _this.Utils.messageBox("只能选择草稿、停用状态的商品");
                        return;
                    }
                });
                this.$ajax.send("omsapi/basicinfo/getallproductclassify", "get", {}, (result) => {
                    _this.productClassifyList = result.Result;
                });
                _this.updateClassifyVisible = isVisible;
            },
            batchUpdateClassify() {
                var _this = this;
                if (_this.productClassify == '') {
                    _this.updateClassifyVisible = false;
                    return;
                } else {
                    _this.multipleSelection.forEach(function (product) {
                        product.ProductTypeCode = _this.productClassify.productClassifyCode;
                        product.ProductTypeName = _this.productClassify.productClassifyName;
                    });
                }
                this.$ajax.send("omsapi/product/batchupdateclassify", "post", _this.multipleSelection, (result) => {
                    _this.Utils.messageBox("批量修改分类成功！", "success");
                    _this.updateClassifyVisible = false;
                    this.reloadPageList();
                    return;
                });
            },
            classifyChange: function (code) {
                if (code == "0") {
                    this.productClassify.productClassifyCode = "0";
                    this.productClassify.productClassifyName = "";
                } else {
                    for (var i = 0; i < this.productClassifyList.length; i++) {
                        var classify = this.productClassifyList[i];
                        if (classify.ProductClassifyCode == code) {
                            this.productClassify.productClassifyCode = classify.Code;
                            this.productClassify.productClassifyName = classify.Name;
                        }
                    }
                }
            }
        },
        components: {
            "ImportProductTemplate": resolve => { require(['./importproducttemplate.vue'], resolve) },
            "ExportProduct": resolve => { require(['./exportexceltemplate.vue'], resolve) },
        }
    }
</script>
<style>
</style>